<template>
  <div class="result-table">
    <div class="setting-nav-title">
      {{ $t("userPageTitle") }}
    </div>
    <div class="setting-tool">
      <div class="searchInput pull-left" v-permission="'User.User'">
        <el-input v-model="searchUserString" :placeholder="placeholder" clearable @keyup.enter.native="getList()">
          <el-button slot="append" icon="el-icon-search" v-on:click="getList()"> </el-button>
        </el-input>
      </div>
      <el-button class="confirm" style="float: right;" @click="addUser" v-permission="'User.UserAdd'">
        {{ $t("createNewUser") }}
      </el-button>
    </div>

    <el-table :data="tableData" v-loading="loading" @row-click="toDetail">
      <el-table-column :label="$t('status')">
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('effective')"
            v-if="scope.row.isEffective"
            placement="top-start"
          >
            <svg-icon icon-class="success"></svg-icon>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" :content="$t('uneffective')" v-else placement="top-start">
            <svg-icon icon-class="error"></svg-icon>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="userName" :label="$t('userName')"> </el-table-column>
      <el-table-column prop="userFullName" :label="$t('name')"> </el-table-column>
      <el-table-column prop="roleName" :label="$t('authority')">
        <template slot-scope="scope">
          <div>
            {{ formatRoleName(scope.row.roleName) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="store" :label="$t('manageStores')" v-if="false">
        <template slot-scope="scope">
          <div>{{ formatShopName(scope.row.displayShopInfo) }}({{ $t("meter") }} {{ scope.row.shopCount }})</div>
        </template>
      </el-table-column>
      <el-table-column width="150">
        <template slot-scope="scope">
          <svg-icon
            icon-class="edit"
            class-name="handle-icon"
            @click.native.stop="edit(scope.row)"
            v-permission="'User.UserEdit'"
          ></svg-icon>
          <svg-icon
            icon-class="lock"
            class-name="handle-icon"
            @click.native.stop="changePassword(scope.row)"
            v-permission="'User.UserEdit'"
          ></svg-icon>
          <svg-icon icon-class="expand" class-name="handle-icon"></svg-icon>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      background
      layout=" prev, pager, next, sizes, total"
      :total="total"
      :page-size="currentPageSize"
      :page-sizes="[10, 15, 20]"
      :current-page="currentPage"
      @current-change="currentChange"
      @size-change="sizeChange"
      class="common-pagination"
    >
    </el-pagination>

    <side-layer :show-side-layer.sync="formDrawer" :wrapperClosable="false" @close="dialogClose">
      <div v-loading="detailLoading">
        <div class="setting-nav-title">
          {{ formType === "add" ? $t("createNewUser") : $t("editUser") }}
        </div>

        <el-form ref="userform" :model="userform" label-position="top" v-if="!detailLoading" :rules="formRules">
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item :label="$t('userName')" prop="userName">
                <el-input clearable v-model="userform.userName" :disabled="formType === 'edit'"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('name')" prop="userFullName">
                <el-input clearable v-model="userform.userFullName"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <!-- <el-col :span="12" v-if="false">
              <el-form-item :label="$t('manageStores')" prop="userShopList">
                <el-select
                  v-if="process.env.VUE_APP_NEWSTOREVERSION === 'true'"
                  v-model="userform.userShopList"
                  style="width: 100%;"
                  filterable
                  v-loading="userShopListLoading"
                  element-loading-spinner="el-icon-loading"
                  @change="shopListChange"
                  :multiple="shopListMuti"
                  :collapse-tags="shopListMuti ? true : false"
                  :disabled="isCurrentUser"
                >
                  <el-option :label="$t('all')" value="select-all"></el-option>
                  <el-option
                    v-for="item in userShopList"
                    :key="item.shopId"
                    :label="item.shopName"
                    :value="item.shopId"
                  >
                  </el-option>
                </el-select>
                <el-select
                  v-else
                  v-model="userform.userShopList"
                  style="width: 100%;"
                  filterable
                  v-loading="userShopListLoading"
                  element-loading-spinner="el-icon-loading"
                  @change="shopListChange"
                  :multiple="shopListMuti"
                  :collapse-tags="shopListMuti ? true : false"
                  :disabled="isCurrentUser"
                >
                  <el-option :label="$t('all')" value="select-all"></el-option>
                  <el-option-group v-for="group in userShopList" :key="group.label" :label="group.label">
                    <el-option
                      v-for="item in group.options"
                      :key="item.shopId"
                      :label="item.shopName"
                      :value="item.shopId"
                    >
                    </el-option>
                  </el-option-group>
                </el-select>
              </el-form-item>
            </el-col> -->
            <el-col :span="12">
              <el-form-item :label="$t('userAuthority')" prop="roleId">
                <el-select
                  v-model="userform.roleId"
                  style="width: 100%;"
                  filterable
                  v-loading="roleListLoading"
                  element-loading-spinner="el-icon-loading"
                  :disabled="isCurrentUser"
                >
                  <el-option
                    v-for="item in roleList"
                    :key="item.roleId"
                    :label="formatRoleName(item.roleName)"
                    :value="item.roleId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('phoneNum')" prop="userPhoneNumber">
                <el-input clearable v-model="userform.userPhoneNumber"> </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item :label="$t('email')" prop="userEmail">
                <el-input clearable v-model="userform.userEmail"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="formType === 'add'">
              <el-form-item :label="$t('password')" prop="userPassword">
                <el-input
                  clearable
                  v-model="userform.userPassword"
                  type="password"
                  show-password
                  :placeholder="$t('invalidPasswordFormat')"
                >
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12" v-if="formType === 'add'">
              <el-form-item :label="$t('passwordRepeat')" prop="userPasswordRepeat">
                <el-input
                  clearable
                  v-model="userform.userPasswordRepeat"
                  type="password"
                  show-password
                  :placeholder="$t('invalidPasswordFormat')"
                >
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <el-form-item :label="$t('status')" prop="isEffective">
                <el-radio
                  :disabled="formType === 'add' || isCurrentUser"
                  v-model="userform.isEffective"
                  :label="true"
                  >{{ $t("effective") }}</el-radio
                >
                <el-radio
                  :disabled="formType === 'add' || isCurrentUser"
                  v-model="userform.isEffective"
                  :label="false"
                  >{{ $t("uneffective") }}</el-radio
                >
              </el-form-item>
            </el-col>
            <el-col :span="24" v-if="formType != 'edit'">
              <el-form-item
                :label="$t('ForceModifyPassword')"
                prop="forceModifyPassword"
                class="textLabel"
                style="min-height: 92px;"
              >
                <el-radio :disabled="formType === 'edit'" v-model="userform.forceModifyPassword" :label="true">{{
                  $t("on")
                }}</el-radio>
                <el-radio :disabled="formType === 'edit'" v-model="userform.forceModifyPassword" :label="false">{{
                  $t("off")
                }}</el-radio>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="10">
            <el-col :span="24">
              <div style="text-align: right;">
                <el-button class="confirm" @click="userFormSubmit" :loading="submitLoading" :disabled="submitLoading">
                  {{ formType === "edit" ? $t("btnEditRuleText") : $t("submit") }}
                </el-button>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </side-layer>

    <side-layer :show-side-layer.sync="detailDrawer">
      <div>
        <div class="setting-nav-title">{{ $t("userDetail") }}</div>

        <div class="name-value" style="margin-bottom: 20px;">
          <table cellspacing="0" cellpadding="0" border="0">
            <tr>
              <td class="name">
                {{ $t("userName") }}
              </td>
              <td class="value">
                {{ detailInfo.userName }}
              </td>
            </tr>

            <tr>
              <td class="name">
                {{ $t("name") }}
              </td>
              <td class="value">
                {{ detailInfo.userFullName }}
              </td>
            </tr>

            <tr>
              <td class="name">
                {{ $t("status") }}
              </td>
              <td class="value">
                {{ detailInfo.isEffective ? $t("effective") : $t("uneffective") }}
              </td>
            </tr>

            <tr>
              <td class="name">
                {{ $t("authority") }}
              </td>
              <td class="value">
                {{ formatRoleName(detailInfo.roleName) }}
              </td>
            </tr>

            <tr>
              <td class="name">
                {{ $t("phoneNum") }}
              </td>
              <td class="value">
                {{ detailInfo.userPhoneNumber }}
              </td>
            </tr>
            <tr>
              <td class="name">
                {{ $t("email") }}
              </td>
              <td class="value">
                {{ detailInfo.userEmail }}
              </td>
            </tr>
            <tr v-if="false">
              <td class="name">
                {{ $t("manageStores") }}
              </td>
              <td class="value">
                <div class="store-list-item" v-for="item in detailInfo.shopList" :key="item.shopId">
                  {{ item.shopName }}
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div v-if="!isCurrentUser">
          <div class="setting-nav-title" v-permission="'User.UserDelete'">
            {{ $t("userNouseAndDel") }}
          </div>

          <div class="explain" v-permission="'User.UserDelete'">
            {{ $t("userLongTimeNouse") }}
          </div>

          <div style="margin-top: 16px;">
            <el-button class="delete" @click="removeDialog = true" v-permission="'User.UserDelete'">{{
              $t("delete")
            }}</el-button>
          </div>
        </div>
      </div>
    </side-layer>

    <side-layer :show-side-layer.sync="passwordDrawer">
      <div class="setting-nav-title">{{ $t("userPasswordChange") }}</div>

      <el-form
        ref="editPassword"
        :model="editPassword"
        :rules="editPasswordRules"
        v-if="passwordDrawer"
        label-width="100px"
        label-position="top"
      >
        <el-form-item :label="$t('name')">
          <el-input v-bind:value="editPassword.userFullName" disabled></el-input>
        </el-form-item>
        <el-form-item :label="$t('newPassword')" prop="userPassword">
          <el-input
            v-model="editPassword.userPassword"
            type="password"
            clearable
            show-password
            autocomplete="off"
            :placeholder="$t('passwordInputHint')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('passwordRepeat')" prop="userPasswordRepeat">
          <el-input
            v-model="editPassword.userPasswordRepeat"
            type="password"
            clearable
            show-password
            autocomplete="off"
            :placeholder="$t('passwordInputHint')"
          >
          </el-input>
        </el-form-item>
      </el-form>

      <div>
        <el-button
          class="delete"
          type="primary"
          @click="editPasswordSubmit"
          :loading="changePasswordLoading"
          :disabled="changePasswordLoading"
        >
          {{ $t("savePassword") }}
        </el-button>
      </div>
    </side-layer>

    <modal-dialog :show-dialog.sync="removeDialog" :dialog-title="$t('userDeleteConfirm')">
      <div class="user-remove-table">
        <table cellspacing="0" cellpadding="0" border="0">
          <tr>
            <td class="name">
              {{ $t("userName") }}
            </td>
            <td class="value">
              {{ detailInfo.userName }}
            </td>
          </tr>

          <tr>
            <td class="name">
              {{ $t("name") }}
            </td>
            <td class="value">
              {{ detailInfo.userFullName }}
            </td>
          </tr>
        </table>
      </div>

      <template v-slot:footer>
        <el-button class="cancel dialog-btn" type="primary" @click="cancel">
          {{ $t("cancel") }}
        </el-button>
        <el-button
          class="delete dialog-btn"
          type="primary"
          @click="userDelete"
          :loading="deleteLoading"
          :disabled="deleteLoading"
        >
          {{ $t("delete") }}
        </el-button>
      </template>
    </modal-dialog>
  </div>
</template>

<script>
import { getUserList, userDelete, shoplist, addUser, modifyUser, resetPassword } from "api/user";
import {
  getAdminUserList,
  createAdminUser,
  deleteAdminUser,
  modifyAdminUser,
  getAdminUserRoleList
} from "api/subAdmin";
import { userRoleList } from "api/permission";
import { getSessionStorage } from "@/utils/storage";
import { isArray, cloneDeep } from "lodash";
import pagination from "@/mixins/pagination";
import { validatePassWord, checkDifferentPassword, validatePhone } from "utils";
export default {
  mixins: [pagination],
  data() {
    var validatePass = (rule, value, callback) => {
      if (!validatePassWord(value)) {
        callback(new Error(this.$t("invalidPasswordFormat")));
      } else {
        if (this.userform.userPasswordRepeat !== "") {
          this.$refs.userform.validateField("userPasswordRepeat");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (!validatePassWord(value)) {
        callback(new Error(this.$t("invalidPasswordFormat")));
      }
      if (!checkDifferentPassword(this.userform.userPassword, value)) {
        callback(new Error(this.$t("differentPasswordInput")));
      } else {
        callback();
      }
    };
    var validateEditPass = (rule, value, callback) => {
      if (!validatePassWord(value)) {
        callback(new Error(this.$t("invalidPasswordFormat")));
      } else {
        if (this.editPassword.userPasswordRepeat !== "") {
          this.$refs.editPassword.validateField("userPasswordRepeat");
        }
        callback();
      }
    };
    var validateEditPass2 = (rule, value, callback) => {
      if (!validatePassWord(value)) {
        callback(new Error(this.$t("invalidPasswordFormat")));
      }
      if (!checkDifferentPassword(this.editPassword.userPassword, value)) {
        callback(new Error(this.$t("differentPasswordInput")));
      } else {
        callback();
      }
    };
    var validatePhoneNum = (rule, value, callback) => {
      if (!validatePhone(value)) {
        callback(new Error(this.$t("userContactInputErr")));
      } else {
        callback();
      }
    };
    return {
      placeholder: this.$t("userName") + " / " + this.$t("name"),
      formRules: {
        userName: { required: true, message: this.$t("userNameInputErr"), trigger: "blur" },
        userFullName: { required: true, message: this.$t("userFullNameInputErr"), trigger: "blur" },
        userShopList: { required: true, message: this.$t("manageStoreInputErr"), trigger: "change" },
        roleId: { required: true, message: this.$t("userRightInputErr"), trigger: "change" },
        userPhoneNumber: [
          { required: true, message: this.$t("userContactInputErr"), trigger: "blur" },
          { validator: validatePhoneNum, trigger: ["blur", "change"] }
        ],
        userEmail: [
          { required: true, message: this.$t("userEmailInputErr"), trigger: "blur" },
          { type: "email", message: this.$t("userEmailInputErr"), trigger: ["blur", "change"] }
        ],
        userPassword: [
          { required: true, message: this.$t("userPWDInputErr"), trigger: "blur" },
          { validator: validatePass, trigger: "blur" }
        ],
        userPasswordRepeat: [
          { required: true, message: this.$t("userPWDReaptInputErr"), trigger: "blur" },
          { validator: validatePass2, trigger: "blur" }
        ],
        isEffective: { required: true, message: this.$t("userStausInputErr"), trigger: "blur" },
        forceModifyPassword: { required: true, message: this.$t("userStausInputErr"), trigger: "blur" }
      },

      editPasswordRules: {
        userPassword: [
          { required: true, message: this.$t("userPWDInputErr"), trigger: "blur" },
          { validator: validateEditPass, trigger: "blur" }
        ],
        userPasswordRepeat: [
          { required: true, message: this.$t("userPWDReaptInputErr"), trigger: "blur" },
          { validator: validateEditPass2, trigger: "blur" }
        ]
      },
      showPasswordExpirationDays: true,
      tableData: [],
      loading: false,
      formDrawer: false,
      deleteLoading: false,
      detailLoading: false,
      changePasswordLoading: false,
      userform: {
        roleId: "",
        userId: "",
        isEffective: true,
        userEmail: "",
        userName: "",
        userFullName: "",
        userPhoneNumber: "",
        userShopList: [],
        forceModifyPassword: true,
        allShop: false
      },

      formType: "",
      submitLoading: false,

      detailDrawer: false,
      detailInfo: {},
      removeDialog: false,
      userShopList: [],
      userShopListLoading: true,
      shopListMuti: true,

      roleList: [],
      roleListLoading: true,

      passwordDrawer: false,
      userId: null,
      userInfo: {},

      editPassword: {
        roleId: "",
        userEmail: "",
        isEffective: true,
        userFullName: "",
        userName: "",
        userPhoneNumber: "",
        userShopList: [],
        userPassword: "",
        userPasswordRepeat: ""
      },
      searchUserString: "",
      isCurrentUser: false
    };
  },
  mounted() {
    this.userInfo = JSON.parse(getSessionStorage("userInfo"));
    this.getList();
    //this.getRoleList();
  },

  methods: {
    dialogClose() {
      this.$refs.userform.resetFields();
    },
    cancel() {
      this.removeDialog = false;
    },
    // 单独处理角色名称
    formatRoleName(roleName) {
      let formatRoleName = "";
      switch (roleName) {
        case "SuperAdmin":
          formatRoleName = this.$t("superAdmin");
          break;
        case "Administrator":
          formatRoleName = this.$t("administrator");
          break;
        case "Manager":
          formatRoleName = this.$t("manager");
          break;
        case "User":
          formatRoleName = this.$t("userRole");
          break;
        default:
          formatRoleName = roleName;
          break;
      }
      return formatRoleName;
    },
    //  单独处理店铺名称
    formatShopName(shopName) {
      let formatShopName = "";
      switch (shopName) {
        case "noshop":
          formatShopName = this.$t("noshop");
          break;
        case "allshop":
          formatShopName = this.$t("allshop");
          break;
        default:
          formatShopName = shopName;
      }
      return formatShopName;
    },
    getList(pageIndex = 1, pageSize = this.currentPageSize) {
      this.loading = true;
      this.currentPage = pageIndex;
      let UserFullName = this.searchUserString;
      getAdminUserList({
        Search: UserFullName,
        PageSize: pageSize,
        PageIndex: pageIndex
      }).then(response => {
        this.loading = false;
        if (response.resultStatus === "Success" && response.statusCode === 200) {
          this.tableData = response.result;
          this.total = response.totalCount;
        }
      });
    },
    async addUser() {
      this.isCurrentUser = false;
      this.formDrawer = true;
      this.shopListMuti = true;
      this.formType = "add";
      this.detailLoading = true;
      this.getRoleList();
      await this.getUserShopList();

      this.userform = {
        roleId: "",
        userEmail: "",
        isEffective: true,
        userFullName: "",
        userName: "",
        userPhoneNumber: "",
        userShopList: [],
        userPassword: "",
        userPasswordRepeat: "",
        forceModifyPassword: true,
        allShop: false,
        enableSystemMessage: true,
        enableSystemEmail: true
      };
      this.detailLoading = false;
    },

    async edit(row) {
      if (row.userId === this.userInfo.userId) {
        this.isCurrentUser = true;
      } else {
        this.isCurrentUser = false;
      }
      this.formDrawer = true;
      this.shopListMuti = true;
      this.formType = "edit";
      this.detailLoading = true;
      this.getRoleList();
      // await this.getUserShopList();
      this.userform = {
        roleId: "",
        userId: "",
        isEffective: true,
        userEmail: "",
        userName: "",
        userFullName: "",
        userPhoneNumber: "",
        userShopList: [],
        forceModifyPassword: true,
        enableSystemMessage: true,
        enableSystemEmail: true
      };
      this.userform.roleId = row.roleId;
      this.userform.userId = row.userId;
      this.userform.isEffective = row.isEffective;
      this.userform.userEmail = row.userEmail;
      this.userform.userName = row.userName;
      this.userform.userFullName = row.userFullName;
      this.userform.userPhoneNumber = row.userPhoneNumber;
      // this.userform.userShopList = row.shopList.map(item => item.shopId);
      this.userform.forceModifyPassword = row.forceModifyPassword;
      this.userform.enableSystemEmail = row.enableSystemEmail;
      this.userform.enableSystemMessage = row.enableSystemMessage;
      this.detailLoading = false;
    },

    changePassword(row) {
      this.passwordDrawer = true;
      this.formType = "changepassword";
      this.detailLoading = true;
      this.editPassword = {
        userId: "",
        userFullName: "",
        userPassword: "",
        userPasswordRepeat: ""
      };
      this.editPassword.userId = row.userId;
      this.editPassword.userFullName = row.userFullName;
      this.detailLoading = false;
    },

    toDetail(row) {
      if (row.userId === this.userInfo.userId) {
        this.isCurrentUser = true;
      } else {
        this.isCurrentUser = false;
      }
      this.detailDrawer = true;
      this.detailInfo = row;
    },

    userDelete() {
      this.deleteLoading = true;
      deleteAdminUser({ userId: this.detailInfo.userId }).then(response => {
        this.deleteLoading = false;
        if (response.resultStatus === "Success" && response.statusCode === 200) {
          this.$message.success(this.$t("success"));
          this.removeDialog = false;
          this.detailDrawer = false;
          if (Math.ceil((this.total - 1) / this.currentPageSize) >= this.currentPage) {
            this.getList(this.currentPage);
          } else {
            this.getList(this.currentPage - 1);
          }
        } else {
          this.$message.error(this.$t("fail"));
        }
      });
    },
    editPasswordSubmit() {
      this.$refs["editPassword"].validate(async valid => {
        if (valid) {
          let data = cloneDeep(this.editPassword);
          data.newPassword = this.editPassword.userPassword;
          this.changePasswordLoading = true;
          resetPassword(data).then(response => {
            this.changePasswordLoading = false;
            if (response.resultStatus === "Success" && response.statusCode === 200) {
              this.$message.success(this.$t("success"));
              this.passwordDrawer = false;
            } else {
              this.$message.error(this.$t("fail"));
            }
          });
        } else {
          return false;
        }
      });
    },
    userFormSubmit() {
      this.$refs["userform"].validate(valid => {
        if (valid) {
          let action = this.formType === "edit" ? modifyAdminUser : createAdminUser;
          let data = cloneDeep(this.userform);
          if (this.userform.userShopList == "select-all") {
            // if (process.env.VUE_APP_NEWSTOREVERSION === "true") {
            // remove env setting for SAAS
            if (this.$settings.newStoreVersion === true) {
              data.userShopList = this.userShopList.map(item => item.shopId);
            } else {
              data.userShopList = [
                ...this.userShopList[0].options.map(item => item.shopId),
                ...this.userShopList[1].options.map(item => item.shopId)
              ];
            }
          }
          this.submitLoading = true;
          action(data).then(response => {
            this.submitLoading = false;
            if (response.resultStatus === "Success" && response.statusCode === 200) {
              this.formDrawer = false;
              this.getList(this.currentPage);
              this.$message.success(this.$t("success"));
            } else {
              this.$message.error(this.$t("fail"));
            }
          });
        } else {
          return false;
        }
      });
    },

    shopListChange(value) {
      if (value[value.length - 1] === "select-all") {
        this.userform.userShopList = "select-all";
        this.userform.allShop = true;
        this.shopListMuti = false;
      } else {
        this.userform.allShop = false;
        if (isArray(value)) {
          this.userform.userShopList = value;
        } else {
          this.userform.userShopList = [value];
        }
        this.shopListMuti = true;
      }
    },
    getRoleList(userId) {
      this.roleListLoading = true;
      getAdminUserRoleList({ userId }).then(response => {
        this.roleListLoading = false;
        if (response.resultStatus === "Success" && response.statusCode === 200) {
          this.roleList = response.result;
        }
      });
    },

    async getUserShopList(userId) {
      this.userShopListLoading = true;
      let response = await shoplist({ userId });
      if (response.resultStatus === "Success" && response.statusCode === 200) {
        // if (process.env.VUE_APP_NEWSTOREVERSION === "true") {
        // remove env setting for SAAS
        if (this.$settings.newStoreVersion === true) {
          this.userShopList = response.result;
        } else {
          this.userShopList = [
            {
              label: this.$t("shopOffline"),
              options: []
            },
            {
              label: this.$t("shopOnline"),
              options: []
            }
          ];

          if (response.result.length > 0) {
            response.result.forEach(item => {
              if (item.isOnline) {
                this.userShopList[1].options.push(item);
              } else {
                this.userShopList[0].options.push(item);
              }
            });
          }
        }
      }
      this.userShopListLoading = false;
    }
  }
};
</script>

<style lang="scss">
.textLabel {
  font-size: 0;
  label.el-form-item__label {
    line-height: 40px;
  }
}
.store-list-item {
  line-height: 30px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-remove-table {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  width: 100%;

  table {
    width: 100%;
    table-layout: fixed;
  }

  td {
    padding: 10px 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    word-wrap: break-word;
  }

  .name {
    vertical-align: middle;
  }
}
</style>
