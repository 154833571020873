import http from "../http";

export const getAdminUserList = data => {
  return http({
    method: "get",
    url: `/api/user/adminuserlist`,
    params: data
  });
};

export const modifyAdminUser = data => {
  return http({
    method: "put",
    url: "/api/user/modifyadminuser",
    data
  });
};

export const deleteAdminUser = data => {
  return http({
    method: "delete",
    url: "/api/user/deleteadmin",
    data
  });
};

export const createAdminUser = data => {
  return http({
    method: "post",
    url: "/api/user/addadmin",
    data
  });
};

export const getAdminUserRoleList = data => {
  return http({
    method: "get",
    url: `/api/permission/rolelistonadminuseredit`,
    params: data
  });
};

export const getAdminUserRoleListWithPermission = data => {
  return http({
    method: "get",
    url: `/api/permission/adminrolelist`,
    params: data
  });
};

export const getAllAdminUserRoleList = data => {
  return http({
    method: "get",
    url: `/api/permission/rolelistonadminroleedit`,
    params: data
  });
};

export const createAdminUserRole = data => {
  return http({
    method: "post",
    url: "/api/permission/addadminrole",
    data
  });
};

export const modifyAdminUserRole = data => {
  return http({
    method: "put",
    url: "/api/permission/modifyadminrole",
    data
  });
};

export const deleteAdminUserRole = data => {
  return http({
    method: "delete",
    url: "/api/permission/deleteadminrole",
    data
  });
};
